@media only screen and (min-width: 240px) {
  .benefits {
    padding: 100px 0;
    background: #1c5177;
  }

  .benefits .intro h2 {
    font-size: 28px;
    font-weight: 700;
    color: #3a3a47;
    color: #ffffff;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  .benefits .intro p {
    padding-top: 10px;
    border-top: 1px solid white;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.01em;
    color: #818198;
    color: #ffffff;

    max-width: 630px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .benefits-icon {
    float: left;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5em;
  }

  .benefits-header {
    margin: 10px 0;
  }

  .benefits-text {
    margin: 10px 0;
  }

  .benefits-header h2 {
    font-size: 36px;
    font-weight: 700;
    color: #364655;
    color: #ffffff;
  }

  .benefits-text h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0.5em;
    color: #364655;
    color: #ffffff;
    text-align: justify;
    text-justify: inter-word;
  }

  .shape {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }

  .shape::before {
    position: absolute;
    display: inline-block;
    width: 100%;
    padding-top: 3.5%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='15' viewBox='0 0 500 15' preserveAspectRatio='none'%3E%3Cpath d='M0,0 L250,15 L500,0 Z' fill='white' /%3E%3C/svg%3E");
    content: '';
    top: 0px;
    background-size: 100% 100%;
  }

  .shape::after {
    position: absolute;
    display: inline-block;
    width: 100%;
    padding-top: 3.5%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='15' viewBox='0 0 500 15' preserveAspectRatio='none'%3E%3Cpath d='M0,0 L250,15 L500,0 Z' fill='%231c5177' /%3E%3C/svg%3E"),
      white;
    content: '';
    bottom: 0px;
    background-size: 100% 100%;
  }

  .cta-sm {
    padding: 100px 0 100px 0;
    background: rgba(30, 166, 154, 0.1);
    background: #f1f6ff;
  }

  .cta-sm .cta-content {
    max-width: 768px;
    margin: 0 auto;
  }

  .cta-sm h1 {
    font-size: 28px;
    font-weight: 600;
    color: #364655;
    line-height: 1.6;
  }

  .cta-sm h4 {
    font-size: 13px;
    font-weight: 400;
    color: #aeaeae;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  .form {
    position: relative;
    max-width: 420px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .chimp-form .mail {
    position: relative;
    background-color: #ffffff;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    transition: all 0.3s;
  }

  .form input {
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #1c5177;
    /* color: #6d48e5; */
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    height: 50px;
    border: 1px solid #1c5177;
    border-radius: 4px;
    background-color: #ffffff;
    outline: none;
    padding: 0 100px 0 20px;
    box-shadow: 0 5px 30px rgba(255, 255, 255, 0.1);
  }

  .form input:focus {
    outline: none !important;
    background: #ffffff;
    border: 1px solid #1c5177;
  }

  .form .submit-button {
    height: 50px;
    width: 100%;
    border: 0;
    border-radius: 4px;
    margin: 0 auto;
    margin: 15px 0;
    padding: 0 25px 0 25px;
    background: #1c5177;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 100, 0.2);
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    transition: 300ms;
  }

  .form .submit-button:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 100, 0.2);
  }

  .form .submit-button:focus,
  .form .submit-button:active {
    background: #1c5177;
  }

  #response {
    position: absolute;
    left: 0;
    bottom: -45%;
    vertical-align: middle;
    font-size: 13px !important;
    font-weight: 500;
    color: #15ccbe;
  }

  .form-note p {
    font-size: 12px;
    font-weight: 500;
    color: #97a6b5;
  }

  .bk-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 3px;
    z-index: 90;
    display: none;
    width: 45px;
    height: 45px;
    background: white;
    color: #f2f2f0;
    border-radius: 50% !important;
    border: 1px solid #1c5177;
    display: table;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .back-to-top {
    font-size: 12px;
    font-weight: 700;
    /* color: #FFFFFF; */
    color: #1c5177;
    text-decoration: none;
    -webkit-transition: 200ms;
    -moz-transition: 200ms;
    -o-transition: 200ms;
    transition: 200ms;
  }
}
