@media only screen and (max-width: 768px) {
  /*------------------------------------------
  ---------------- Info-card -----------------
  ------------------------------------------*/

  .info-card {
    display: block;
    /* vertical-align: middle; */
    align-items: center;
  }

  .info-card .info-icon {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 30px;
    height: 30px;
  }

  .info-card .text-area {
    width: 100%;
    height: auto;
  }

  /***************************
   *   HOW IT WORKS SECTION   *
   ***************************/
  .carousel-root .carousel.carousel-slider .control-arrow {
    top: auto;
    background: #888;
  }

  .carousel.carousel-slider .control-prev.control-arrow {
    border-radius: 10px 0 0 10px;
  }

  .carousel.carousel-slider .control-next.control-arrow {
    border-radius: 0 10px 10px 0;
  }
}
