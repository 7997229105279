@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./AppTablet.css');
@import url('./AppMobile.css');
@import url('react-responsive-carousel/lib/styles/carousel.min.css');

@layer utilities {
  .video-clip-path {
    clip-path: 0 0;
  }

  .translate-z-0 {
    transform: translateZ(0);
  }
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf')
      format('truetype');
}

#loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  opacity: 1;
  background-color: #f2f2f2;
  z-index: 9999;
  text-align: center;
}

.container-m {
  max-width: 1024px;
  margin: 0 auto !important;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

/*------------------------------------------
-------------- Media Queries ---------------
------------------------------------------*/

body {
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter';
}

.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
}

.share {
  white-space: nowrap;
}

.Collapsible {
  background-color: white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid #efefef;
  padding: 10px;
  background: #efefef;
  color: black;
}

.Collapsible__trigger:after {
  content: '▶';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(90deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.benefits-icon img {
  width: 70px;
}

/* =============================== */
/* Model description editor styles */
/* =============================== */
.milkdown .editor p {
  margin: 0 0 10px;
  margin-bottom: 10px;
}

.milkdown .editor li {
  margin-bottom: 10px;
}

.milkdown .editor h1 {
  font-size: 2.17em;
  font-weight: 400;
  line-height: 1;
  margin: 10px 0;
}

.milkdown .editor h2 {
  font-size: 1.74em;
  font-weight: 400;
  margin: 10px 0;
}

.milkdown .editor h3 {
  font-size: 1.31em;
  font-weight: 400;
  margin: 10px 0;
}

.milkdown .editor h4 {
  font-size: 1em;
  font-weight: 400;
  margin: 0;
}

.milkdown .editor h5 {
  font-size: 00.8em;
  font-weight: 400;
  margin: 0;
}

.milkdown .editor h6 {
  font-size: 00.65em;
  font-weight: 400;
  margin: 0;
}

.milkdown .editor blockquote {
  margin: 10px 0;
  margin-left: 0;
  padding-left: 5px;
  border-left: solid 2px #333;
}

.milkdown .editor pre {
  background-color: inherit;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 10px;
  overflow: visible;
  padding: 0;
  white-space: pre-wrap;
  word-break: inherit;
  word-wrap: break-word;
}

.milkdown .editor strong,
.milkdown .editor b {
  font-weight: 700;
}

.milkdown .editor i,
.milkdown .editor em {
  font-style: italic;
}

.milkdown .editor a {
  text-decoration: none;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.milkdown .editor a:hover {
  text-decoration: underline;
}

.milkdown .editor h3 + h4,
.milkdown .editor h4 + h5,
.milkdown .editor h5 + h6 {
  margin-top: 00.6em;
}

.milkdown .editor img {
  border: 0;
  display: block;
  float: none;
  margin: auto;
  max-width: 100%;
  position: relative;
  max-width: 70%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.milkdown .editor table {
  border-collapse: collapse;
  empty-cells: show;
}

.milkdown .editor td,
.milkdown .editor th {
  border: 1px solid #bdbdbd;
  height: 20px;
  min-width: 20px;
  padding: 2px 5px;
  vertical-align: middle;
}

.milkdown .editor td,
.milkdown .editor th {
  border-style: dashed;
}

.milkdown .editor th {
  background-color: #e0e0e0;
}

.milkdown .editor ol {
  padding-left: 40px;
}

.milkdown .editor ol li {
  list-style: decimal;
}

.milkdown .editor ul {
  list-style: disc;
  padding-left: 2rem;
}

.milkdown .editor ul.a {
  list-style-type: circle;
}

.milkdown .editor ul.b {
  list-style-type: square;
}

.milkdown .editor ol.c {
  list-style-type: upper-roman;
}

.milkdown .editor ol.d {
  list-style-type: lower-alpha;
}

.milkdown .editor:focus {
  outline: 0;
}

.popover-form {
  margin: 10px;
}

.popover-form label {
  margin-bottom: 2px;
}

.tooltip-inner {
  max-width: 98%;
  text-align: left;
  white-space: pre-line;
}

.info-card {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.info-card .info-icon {
  width: 5%;
  min-width: 30px;
  height: 30px;
  margin-right: 15px;
}

.info-card .text-area {
  width: 95%;
  height: auto;
}

.clickable-image input[type='checkbox']:checked ~ * img {
  transform: scale(1.3);
  cursor: zoom-out;
}

/* ===== Terms ===== */
.terms {
  margin-bottom: 50px;
}

.terms h2 {
  font-size: 28px;
  font-weight: 600;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
}

.terms h3 {
  font-size: 20px;
  font-weight: 600;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
  display: block !important;
}

.terms h4 {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
  display: block !important;
}

.terms h5 {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
  display: block !important;
}

.terms div {
  margin-top: 10px;
}

.terms p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
  /* color: #818198; */
  /* max-width: 630px; */
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.terms ul {
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.terms ul li {
  list-style-type: disc;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.terms ul ul {
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.terms ul ul li {
  list-style-type: circle;
}

.terms ol {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.terms ol li {
  list-style-type: lower-roman;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

/*------------------------------------------
----------------- Privacy ------------------
------------------------------------------*/
.privacy {
  margin-bottom: 50px;
}

.privacy h2 {
  font-size: 28px;
  font-weight: 600;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
}

.privacy h3 {
  font-size: 20px;
  font-weight: 600;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
  display: block !important;
}

.privacy h4 {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: #3a3a47;
  line-height: 1.3;
  margin-bottom: 10px;
  display: block !important;
}

.privacy div {
  margin-top: 10px;
}

.privacy p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
  /* color: #818198; */
  /* max-width: 630px; */
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.privacy ul {
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.privacy ul li {
  list-style-type: disc;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ul ul {
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ul ul li {
  list-style-type: circle;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ol {
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.privacy ol li {
  list-style-type: decimal;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ol ol {
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ol ol li {
  list-style-type: lower-alpha;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ol li ul li {
  list-style-type: disc;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ol li ul li ul li {
  list-style-type: circle;
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy ul ol {
  margin: 0 auto;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.privacy table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

/***************************
*   HOW IT WORKS SECTION   *
***************************/
/** Section: This part can be removed if we want to preserve the aspect ratio  */
.carousel-slider {
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
}

.carousel-slider .slider-wrapper {
  height: 100%;
  max-width: 100%;
}

.carousel-slider .slider-wrapper .slider {
  height: 100%;
  max-width: 100%;
}

.carousel-slider .slider-wrapper .slider .slide {
  height: 100%;
  max-width: 100%;
}

/** End of section: This part can be removed if we want to preserve the aspect ratio  */
/***************************
*     QUOTES SECTION     *
***************************/
.quote {
  margin-top: 100px;
  padding: 50px 0;
  background-color: rgba(248, 249, 249) !important;
}

.quote h2 {
  font-size: 36pt;
  font-weight: 500;
  color: #4a4a4a;
  /* text-transform: uppercase; */
}

summary::marker {
  content: '';
}
